import React from "react";
import "../src/App.css";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import HomePage from "./view/HomePage";
import Dashboard from "./view/Dashboard";
import Login from "./component/Login";
import SignUp from "./component/SignUp";
import ProtectedRoutes from "./routes/ProtectedRoutes";
import PrivactAndPolicy from "./component/PrivactAndPolicy";
import TermsAndCondition from "./component/TermsAndCondition";

function App() {
  return (
    <>
      <Router>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/login" element={<Login />} />
          <Route path="/signUp" element={<SignUp />} />
          <Route path="/privacypolicy" element={<PrivactAndPolicy />} />
          <Route path="/termsandConditions" element={<TermsAndCondition />} />
          <Route
            path="/*"
            element={
              <ProtectedRoutes>
                <Dashboard />
              </ProtectedRoutes>
            }
          />
        </Routes>
      </Router>
    </>
  );
}

export default App;
