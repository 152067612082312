import React, { useState } from "react";
import PropTypes from "prop-types";
import { Box, Tab, Tabs } from "@mui/material";
import RssFeedIcon from "@mui/icons-material/RssFeed";
import NewspaperIcon from "@mui/icons-material/Newspaper";
import TelegramIcon from "@mui/icons-material/Telegram";
import GroupsIcon from "@mui/icons-material/Groups";
import AddCircleOutlineRoundedIcon from "@mui/icons-material/AddCircleOutlineRounded";
import Blogs from "./Blogs";
import Feed from "./Feed";
import FriendAndGroups from "./FriendAndGroups";
import CreatePost from "./CreatePost";
import Chats from "./Chats";

const tabTitles = [
  "Feed",
  "Blogs",
  "Messages",
  "Manage Friends & Groups",
  "Create a Post",
];
function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      style={{ height: "100%" }}
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function Community({ changeTab }) {
  const [value, setValue] = useState(changeTab ?? 0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "0.5rem",
          position: "sticky",
          background: `linear-gradient(to right, #262829, #393B3D)`,
          borderBottom: "1px solid #3F3F3F",
          zIndex: 1,
        }}
      >
        <Box
          sx={{
            fontFamily: "Poppins, sans-serif",
            fontWeight: 700,
            fontSize: { xs: "2.4rem", sm: "3.6rem", md: "4rem" },
            lineHeight: { xs: "3.8rem", sm: "5.4rem", md: "6rem" },
            color: "white",
            maxWidth: { xs: "20rem", sm: "none" },
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {tabTitles[value]}
        </Box>

        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "#686DE0",
            borderRadius: "1.2rem",
            padding: "0.3rem",
          }}
        >
          <Tabs
            value={value}
            onChange={handleChange}
            sx={{
              "& .MuiTab-root": {
                minWidth: "auto",
                padding: "1rem",
              },
              "& .MuiTabs-indicator": {
                display: "none",
              },
            }}
          >
            {[
              RssFeedIcon,
              NewspaperIcon,
              TelegramIcon,
              GroupsIcon,
              AddCircleOutlineRoundedIcon,
            ].map((IconComponent, index) => (
              <Tab
                key={index}
                icon={
                  <IconComponent
                    sx={{
                      height: "3rem",
                      width: "3rem",
                      color: "white",
                      backgroundColor:
                        value === index ? "#6794E0" : "transparent",
                      borderRadius: "0.5rem",
                      padding: "0.3rem",
                      transition: "background-color 0.3s",
                    }}
                  />
                }
                {...a11yProps(index)}
              />
            ))}
          </Tabs>
        </Box>
      </Box>

      <Box
        sx={{
          height: "calc(100% - 8.5rem)",
          overflowY: "auto",
          "&::-webkit-scrollbar": {
            width: 6,
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: "grey",
            borderRadius: "10px",
          },
        }}
      >
        <Box sx={{ flex: 1, overflowY: "auto" }}>
          <CustomTabPanel value={value} index={0}>
            <Feed />
          </CustomTabPanel>
          <CustomTabPanel value={value} index={1}>
            <Blogs />
          </CustomTabPanel>
          <CustomTabPanel value={value} index={2}>
            <Chats />
          </CustomTabPanel>
          <CustomTabPanel value={value} index={3}>
            <FriendAndGroups />
          </CustomTabPanel>
          <CustomTabPanel value={value} index={4}>
            <CreatePost />
          </CustomTabPanel>
        </Box>
      </Box>
    </>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default Community;
