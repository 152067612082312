import { Box } from "@mui/material";
import React from "react";
import Logo from "../Assests/Logo/Logo.svg";
import { Link } from "react-router-dom";

function Header({ color }) {
  return (
    <Box
      sx={{
        maxHeight: "8rem",
        display: "flex",
        alignItems: "center",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: { xs: "0.4rem 2rem", sm: "0.4rem 4rem", md: "0.4rem 6rem" },
          width: "100%",
          background: color,
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box
            component="img"
            src={Logo}
            alt="Logo"
            sx={{
              height: {
                xs: "4rem",
                sm: "5rem",
                md: "7rem",
              },
            }}
          />
        </Box>

        <Box>
          <Box
            component="ul"
            sx={{
              listStyle: "none",
              display: "flex",
              alignItems: "center",
              color: "white",
              gap: { xs: "2rem", sm: "2rem", md: "3rem" },
              fontFamily: "Poppins, sans-serif",
              fontWeight: 500,
              fontSize: { xs: "1.8rem", sm: "2rem", md: "2.2rem" },
              lineHeight: { xs: "2.5rem", sm: "2.5rem", md: "3.3rem" },
              cursor: "pointer",
              padding: 0,
              margin: 0,
            }}
          >
            <Link to="/" style={{ textDecoration: "none", color: "inherit" }}>
              <Box component="li">Home</Box>
            </Link>
            <Link
              to="/login"
              style={{ textDecoration: "none", color: "inherit" }}
            >
              <Box component="li">Blogs</Box>
            </Link>
            <Link
              to="/login"
              style={{ textDecoration: "none", color: "inherit" }}
            >
              <Box component="li">Login</Box>
            </Link>
            <Link
              to="/signUp"
              style={{ textDecoration: "none", color: "inherit" }}
            >
              <Box component="li">Sign Up</Box>
            </Link>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default Header;
